export class ContactForm {

	constructor() {
		if (!document.querySelector('.js-contact-form')) {
			return;
		}
		this.listenForOverlayClose();
		this.openIfSubmitMessage();
	}

	listenForOverlayClose() {
		document.addEventListener('overlayClosed', (e: any) => {
			if (e.detail.id === 'contact-form-overlay') {
				if (this.getSubmitMessageElement()) {
					this.reloadPageAndClearForm();
					return;
				}
				const formElement = document.querySelector('.js-contact-form form') as HTMLFormElement;
				if (formElement) {
					formElement.reset();
				}
			}
		});
	}

	openIfSubmitMessage() {
		const submitMessageElement = this.getSubmitMessageElement();
		if (submitMessageElement) {
			// show the overlay
			submitMessageElement.closest('.js-overlay').classList.add('is-active');
		}
	}

	getSubmitMessageElement() {
		return document.querySelector('.js-form-submit-message');
	}

	reloadPageAndClearForm() {
		window.location.href = window.location.href;
	}

}