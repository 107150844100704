import Martine from "../vendor/martine";
import { Locker } from "./locker";

declare var Gumshoe: any; 

export class Nav extends Locker {

	constructor() {
		super();
		this.attachClickEvents();
	}

	private attachClickEvents() {
		this.mobileNavTiggerEvent();
		this.setupScrollSpy();
	}

	private mobileNavTiggerEvent() {
		document.querySelector('[data-nav-open]').addEventListener('click', () => {
			document.querySelector('[data-nav-container]').classList.toggle('is-active');
			this.lockSite();
		});

		const closeButtons = document.querySelectorAll('[data-nav-close]');
		Martine.each(closeButtons, (button: HTMLButtonElement) => {
			button.addEventListener('click', () => {
				if (document.querySelector('[data-nav-container].is-active')) {
					document.querySelector('[data-nav-container]').classList.remove('is-active');
					this.unLockSite();
				}
			});
		});

	}

	private setupScrollSpy() {
		var isHomepage = document.querySelector('.js-is-homepage');
		if (!isHomepage) {
			return;
		}
		var header = document.querySelector('.js-header');
		var spy = new Gumshoe('.c-nav__list a', {
			offset: function () {
				return header.getBoundingClientRect().height + 50;
			}
		});
	}

}