export class Utils {

	public static scrollTo(to = 0, duration = 750) {

		const start = window.scrollY;
		const change = to - start;
		const increment = 20;
		let currentTime = 0;

		const animateScroll = (() => {

			currentTime += increment;

			const val = Utils.easeInOutQuad(currentTime, start, change, duration);

			window.scrollTo(0, val);

			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		});

		animateScroll();
	};

	public static easeInOutQuad(t: number, b: number, c: number, d: number) {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t + b;
		t--;
		return -c / 2 * (t * (t - 2) - 1) + b;
	}

	public static checkStatusCode(response: Response) {
		if (response.status === 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response.status);
		}
	}

}