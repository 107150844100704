export class FeatureDetection {

	constructor() {
		this.detectFlexboxSupport();
		this.isTouch();
		this.hasJavaScript();
	}

	private detectFlexboxSupport() {

		const testElem = document.createElement('div');
		const body = document.querySelector('body');

		if (testElem.style.flex === undefined && testElem.style.flexFlow === undefined) {
			body.classList.add('no-flexbox');
		} else {
			body.classList.add('has-flexbox');
		}

	}

	private hasJavaScript() {
		const htmlElement = document.querySelector('html');
		htmlElement.classList.remove('no-js');
		htmlElement.classList.add('js');
	}

	private isTouch() {
		const body = document.querySelector('body');
		const touchClass = 'is-touch';
		if ('ontouchstart' in document) {
			var addTouchClass = function () {
				body.classList.add(touchClass);
				document.removeEventListener('touchstart', addTouchClass);
			};
			document.addEventListener('touchstart', addTouchClass);
		}
	}

}