import Martine from "../vendor/martine";

export class CookieNotice {

	private readonly cookieAcceptedName = "AirportalMarketing.CookiesAccepted";

	constructor() {
		if (document.querySelector('.js-cookie-notice')) {
			this.attachContinueEvent();
		}
	}

	attachContinueEvent() {

		document.querySelector('.js-cookie-notice-continue').addEventListener('click', () => {
			document.querySelector('.js-cookie-notice').classList.remove('is-active');
			Martine.addCookie(this.cookieAcceptedName, 'true', 365);
		});

	}

}