import Martine from "../vendor/martine";
import { Utils } from "./utils";

export class ScrollTo {

	constructor() {
		if (document.querySelector('[data-scroll-to]')) {
			this.attachScrollToEvents();
		}
		const locationHash = window.location.hash;
		if (locationHash) {
			this.scrollToHash(locationHash);
		}
	}

	attachScrollToEvents() {

		const scrollTriggers = document.querySelectorAll('[data-scroll-to]');

		Martine.each(scrollTriggers, (trigger: HTMLLinkElement) => {

			trigger.addEventListener('click', (e) => {

				const elementToScrollTo = this.getElementToScrollTo(trigger);
				if (!elementToScrollTo) {
					return;
				}
				e.preventDefault();
				this.scrollToElement(elementToScrollTo, this.getOffset(trigger));

			});

		});

	}

	scrollToHash(hash: string) {
		const targetElement = document.querySelector(hash);
		if (targetElement) {
			this.scrollToElement(targetElement);
		}
	}

	scrollToElement(elementToScrollTo: Element, offset = 0) {
		const heightOfHeader = document.querySelector('.js-header').clientHeight;
		const elementScrollToTop = elementToScrollTo.getBoundingClientRect().top + offset;
		const scrollTo = elementScrollToTop + window.scrollY - heightOfHeader;

		if (scrollTo !== window.scrollY) {
			Utils.scrollTo(scrollTo, 400);
		}
		
	}

	getElementToScrollTo(linkElement: HTMLLinkElement) {

		let href = linkElement.getAttribute('href');

		if (href.indexOf('/') > -1) {
			href = href.split('/')[1];
		}

		return document.querySelector(href);
	}

	getOffset(trigger: HTMLLinkElement) {

		const desktopOffset = trigger.getAttribute('data-scroll-to-offset-desktop');
		const mobileOffset = trigger.getAttribute('data-scroll-to-offset-mobile');

		if (desktopOffset && Martine.viewport(window, document).width > 980) {
			return parseInt(desktopOffset);
		}

		if (mobileOffset && Martine.viewport(window, document).width < 980) {
			return parseInt(mobileOffset);
		}

		return 0;
	}

}