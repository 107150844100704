import Martine from "../vendor/martine";

export class Accordion {

	constructor() {
		if (document.querySelector('.js-accordion-trigger')) {
			this.attachAccordionEvents();
		}
	}

	attachAccordionEvents() {

		const accordionTriggers = document.querySelectorAll('.js-accordion-trigger');

		Martine.each(accordionTriggers, (trigger: HTMLElement) => {

			trigger.addEventListener('click', (e) => {

				e.preventDefault();

				const target = document.querySelector(trigger.getAttribute('data-accordion-target'));

				trigger.classList.toggle('is-open');
				target.classList.toggle('is-open');

			});

		});

	}

}