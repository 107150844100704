import Martine from "../vendor/martine";
import { Locker } from "./locker";

export class Overlay extends Locker {

	private activeCSSClass = 'is-active';

	constructor() {
		super();
		if (!document.querySelector('.js-overlay')) {
			return;
		}
		this.attachOpenButtonClicks();
		this.attachCloseButtonClicks();
		this.attachOverlayOutsideClicks();
		this.attachEscapeEvent();
	}

	open(id: string, contentSelector?: string) {
		this.closeAnyOpenOverlays();
		const overlayToOpen = document.getElementById(id);
		if (contentSelector) {
			const contentForOverlay = document.querySelector(contentSelector);
			if (contentForOverlay) {
				const content = contentForOverlay.outerHTML;
				if (overlayToOpen.querySelector('[data-overlay-placeholder]')) {
					overlayToOpen.querySelector('[data-overlay-placeholder]').innerHTML = content;
				}
			}
		}
		overlayToOpen.classList.add(this.activeCSSClass);
		this.lockSite();
	}

	close(id: string) {
		const overlayToClose = document.getElementById(id);
		overlayToClose.classList.remove(this.activeCSSClass);

		this.triggerEvent('overlayClosed', id);
		this.unLockSite();
	}

	closeAnyOpenOverlays() {
		const openOverlays = document.querySelectorAll(`.js-overlay.${this.activeCSSClass}`);

		Martine.each(openOverlays, (overlay: HTMLButtonElement) => {
			overlay.classList.remove(this.activeCSSClass);
		});
	}

	attachOpenButtonClicks() {
		const openButtons = document.querySelectorAll('.js-overlay-open');
		Martine.each(openButtons, (button: HTMLButtonElement) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const overlayIdToOpen = this.getOverlayToOpen(button);
				const contentSelector = button.getAttribute('data-overlay-content');
				this.open(overlayIdToOpen, contentSelector);
			});
		});

	}

	getOverlayToOpen(button: HTMLButtonElement) {
		if (this.isMobile() && button.getAttribute('data-overlay-open-mobile-id')) {
			return button.getAttribute('data-overlay-open-mobile-id');
		}
		return button.getAttribute('data-overlay-open-id');
	}

	isMobile() {
		return Martine.viewport(window, document).width < 980;
	}

	attachCloseButtonClicks() {
		const closeButtons = document.querySelectorAll('.js-overlay-close');
		Martine.each(closeButtons, (button: HTMLButtonElement) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const overlayIdToClose = button.getAttribute('data-overlay-close-id');
				this.close(overlayIdToClose);
			});
		});

	}

	attachOverlayOutsideClicks() {
		const overlays = document.querySelectorAll('.js-overlay');
		Martine.each(overlays, (overlay: HTMLDivElement) => {
			overlay.addEventListener('click', (e) => {
				const targetElement = e.target as HTMLDivElement;
				if (targetElement.classList.contains('js-overlay')) {
					this.close(targetElement.id);
				}
			});
		});
	}

	attachEscapeEvent() {
		document.addEventListener('keydown', (e) => {
			const key = e.code || e.key;
			if (key.toLowerCase() === 'escape' || key.toLowerCase() === 'esc') {
				this.closeAllOverlays();
			}
		});
	}

	closeAllOverlays() {
		const overlays = document.querySelectorAll('.js-overlay');
		Martine.each(overlays, (overlay: HTMLDivElement) => {
			if (overlay.classList.contains(this.activeCSSClass)) {
				this.close(overlay.id);
			}
		});
	}

	triggerEvent(eventName: string, overlayId: string) {
		if (window.CustomEvent) {
			const event = new CustomEvent(eventName, {
				detail: {
					id: overlayId
				}
			});
			document.dispatchEvent(event);
		} 
	}

}