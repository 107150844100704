import Martine from "../vendor/martine";

export class MobileReveal {

	constructor() {
		if (document.querySelector('.js-mobile-reveal')) {
			this.attachShowMoreEvent();
		}
	}

	attachShowMoreEvent() {

		const revealMoreButtons = document.querySelectorAll('.js-mobile-reveal');

		Martine.each(revealMoreButtons, (trigger: HTMLElement) => {

			trigger.addEventListener('click', (e) => {

				const targets = document.querySelectorAll(trigger.getAttribute('data-mobile-reveal-target'));

				trigger.classList.toggle('is-active');

				const revealMoreTextElement = trigger.querySelector('.js-mobile-reveal-text');

				if (trigger.classList.contains('is-active')) {
					revealMoreTextElement.textContent = trigger.getAttribute('data-mobile-reveal-less-text');
				} else {
					revealMoreTextElement.textContent = trigger.getAttribute('data-mobile-reveal-more-text');
				}

				Martine.each(targets, (target: HTMLElement) => {
					target.classList.toggle('is-hidden-mobile');
				});
				
			});

		});

	}

}