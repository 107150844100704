import { CookieNotice } from "./modules/cookie-notice";
import { FeatureDetection } from "./modules/feature-detection";
import { LazyLoad } from "./modules/lazyload";
import { Overlay } from "./modules/overlay";
import { ContactForm } from "./modules/contact-form";
import { Accordion } from "./modules/accordion";
import { MobileReveal } from "./modules/mobile-reveal";
import { Nav } from "./modules/nav";
import { ScrollTo } from "./modules/scroll-to";
import { ScrollAnimations } from "./modules/scroll-animations";

new CookieNotice();
new LazyLoad(); 
new FeatureDetection();
new Overlay();
new ContactForm();
new Accordion();
new MobileReveal();
new Nav();
new ScrollTo();
new ScrollAnimations();