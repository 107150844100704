declare var picturefill: any;

export class LazyLoad {

	constructor() {
		this.init();
	}

	init() {
		const lazyLoadTriggerSelector = ".js-lazy-load";
		const lazyImages = [].slice.call(document.querySelectorAll(lazyLoadTriggerSelector));
		var self = this;

		if ("IntersectionObserver" in window) {

			var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
				entries.forEach(function (entry) {
					if (entry.isIntersecting) {
						var lazyImage = entry.target as HTMLImageElement;
						self.lazyLoadImage(lazyImage);
						lazyImageObserver.unobserve(lazyImage);
					}
				});
			});

			lazyImages.forEach(function (lazyImage: HTMLImageElement) {
				lazyImageObserver.observe(lazyImage);
			});

		} else {

			// Fall back for IntersectionObserver
			lazyImages.forEach(function (lazyImage: HTMLImageElement) {
				self.lazyLoadImage(lazyImage, false);
			});

		}

	}

	lazyLoadImage(lazyImage: HTMLImageElement, supportsPicture: boolean = true) {
		const loadedClass = "has-loaded";
		if (lazyImage.getAttribute('data-src')) {
			lazyImage.src = lazyImage.getAttribute('data-src');
		}

		if (lazyImage.getAttribute('data-srcset')) {
			lazyImage.srcset = lazyImage.getAttribute('data-srcset');
		}

		const parent = lazyImage.parentNode as HTMLPictureElement;
		if (parent.tagName === 'PICTURE') {
			const sources = [].slice.call(parent.querySelectorAll('source'));
			sources.forEach(function (source: HTMLSourceElement) {
				if (source.getAttribute('data-srcset')) {
					source.srcset = source.getAttribute('data-srcset');
				}
				if (!supportsPicture && source.classList.contains('js-lazy-load-desktop-image')) {
					lazyImage.src = source.getAttribute('data-srcset');
				}
			});
		}
		lazyImage.addEventListener('load', () => {
			lazyImage.classList.add(loadedClass);
		});
			
	}

}