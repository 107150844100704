export class Locker {

	public currentScrollTop = 0;

	public lockSite(scrollPosition: number = window.scrollY || document.documentElement.scrollTop) {
		this.currentScrollTop = scrollPosition;
		document.body.classList.add('is-locked');
		document.documentElement.classList.add('is-locked');
		document.body.style.marginTop = (this.currentScrollTop * -1) + 'px';
	}

	public unLockSite() {
		document.body.classList.remove('is-locked');
		document.documentElement.classList.remove('is-locked');
		document.body.style.marginTop = '0px';
		window.scrollTo(0, this.currentScrollTop);
	}

}