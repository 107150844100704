import Martine from "../vendor/martine";

declare var AOS: any;

export class ScrollAnimations {

	constructor() {	
		AOS.init({
			offset: 500,
			once: true,
			disable: function () {
				return Martine.viewport(window, document).width < 980
			}
		});
	}

}